<template>
  <div>
    <div class="_BoxHeader">
      <span>新增问题记录</span>
    </div>
    <div class="tab">
      <el-button
        v-for="(item, index) in tabList"
        :key="index"
        :type="[index == tabIndex ? 'primary' : '']"
        @click="clickTab(index, item.value)"
        >{{ item.name }}</el-button
      >
    </div>
    <el-table
      :height="elementHeight"
      :data="tableList"
      style="width: 100%"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      id="table"
    >
      <!-- <el-table-column type="selection"> </el-table-column> -->
      <el-table-column prop="question" label="问题" width="600">
      </el-table-column>
      <!-- <el-table-column prop="extractCount" label="抽取次数"> </el-table-column> -->
      <el-table-column prop="probability" label="是否导入题库">
        <template slot-scope="scope">
          <el-button
            @click="iSImportQuestion(scope.row, false)"
            style="margin-right: 5px"
            >不导入</el-button
          >
          <el-button
            style="margin-right: 5px"
            type="primary"
            @click="iSImportQuestion(scope.row, true)"
            >导入</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="题库"
      fullscreen
      :visible.sync="dialogVisibleAsk"
      :before-close="handleClose"
    >
      <el-table
        :data="questionList"
        :height="elementHeight"
        style="width: 100%"
        :header-cell-style="{
          background: '#E8EBF4',
          color: '#5C5C5C',
          borderColor: '#C0C0C0',
        }"
        border
        @cell-dblclick="toChange"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column type="selection" width="80" v-if="isUse">
        </el-table-column>
        <el-table-column prop="CompName" label="公司" width="180">
        </el-table-column>
        <el-table-column prop="DeptName" label="部门" width="150">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.DeptName"
              clearable
              placeholder="请选择"
              @change="changDept"
            >
              <el-option
                v-for="item in deptList"
                :key="item.value"
                :label="item.deptmentName"
                :value="{ value: item.id, label: item.deptmentName }"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="PostName" label="岗位" width="150">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.PostName"
              @change="changPost"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in postList"
                :key="item.value"
                :label="item.postName"
                :value="item.postName"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="FunctionName" label="职能" width="180">
          <template slot-scope="scope">
            <el-input type="textarea" v-model="scope.row.FunctionName">{{
              scope.row.FunctionName
            }}</el-input>
          </template>
        </el-table-column>
        <el-table-column prop="FileNo" label="文件编号" width="180">
          <template slot-scope="scope">
            <el-input type="textarea" v-model="scope.row.FileNo">{{
              scope.row.FileNo
            }}</el-input>
          </template>
        </el-table-column>
        <el-table-column prop="FileName" label="文件名称" width="200">
          <template slot-scope="scope">
            <el-input type="textarea" v-model="scope.row.FileName">{{
              scope.row.FileName
            }}</el-input>
          </template>
        </el-table-column>
        <el-table-column prop="Question" label="检查内容" width="250">
        </el-table-column>
        <el-table-column prop="Answer" width="250" label="标准答案">
          <template slot-scope="scope">
            <el-input type="textarea" v-model="scope.row.Answer">{{
              scope.row.Answer
            }}</el-input>
          </template>
        </el-table-column>
        <el-table-column prop="RiskPoints" label="风险点" width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.RiskPoints" type="textarea">{{
              scope.row.RiskPoints
            }}</el-input>
          </template></el-table-column
        >
        <el-table-column prop="RiskMeasures" label="风险应对措施" width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.RiskMeasures" type="textarea">{{
              scope.row.RiskMeasures
            }}</el-input>
          </template>
        </el-table-column>
        <el-table-column prop="Version" label="版本号" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.Version">{{
              scope.row.Version
            }}</el-input>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="检查类别">
          <el-table-column prop="" label="1">
            <el-table-column prop="AreaAudit" label="现场检查">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.AreaAudit ? '√' : '' }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="FileAudit" label="文件检查">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.FileAudit ? '√' : '' }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="IsCurrency" label="通用">
          <template slot-scope="scope">
            <div>
              {{ scope.row.IsCurrency ? '√' : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="IsFuncCurrency" label="职能通用">
          <template slot-scope="scope">
            <div>
              {{ scope.row.IsFuncCurrency ? '√' : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="IsSpecial" label="专指">
          <template slot-scope="scope">
            <div>
              {{ scope.row.IsSpecial ? '√' : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="" align="center" label="所在区域">
          <el-table-column prop="arrArea" label="1">
            <el-table-column label="厂区区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('厂区区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="arrArea" label="车间区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('车间区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="3">
            <el-table-column prop="" label="仓库区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('仓库区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="4">
            <el-table-column prop="" label="实验室区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('实验室区域')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="5">
            <el-table-column prop="" label="办公区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('办公区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="6">
            <el-table-column prop="" label="饭堂区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('饭堂区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="7">
            <el-table-column prop="" label="宿舍区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('宿舍区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="8">
            <el-table-column prop="" label="工程区域">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(',').includes('工程区域') ? '√' : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="center" label="检查范畴">
          <el-table-column prop="" label="1">
            <el-table-column prop="" label="安全生产">
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                      scope.row.ArrAuditType.split(',').includes('安全生产')
                        ? '√'
                        : ''
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="2">
            <el-table-column prop="" label="领导干部现场带班">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '领导干部现场带班'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="3">
            <el-table-column prop="" label="三违行为管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('三违行为管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="4">
            <el-table-column prop="" label="班组岗位达标安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '班组岗位达标安全'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="5">
            <el-table-column prop="" label="安全教育作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('安全教育作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="6">
            <el-table-column prop="" label="交叉作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('交叉作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="7">
            <el-table-column prop="" label="相关方施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('相关方施工安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="8">
            <el-table-column prop="" label="特种作业人员管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '特种作业人员管理'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="9">
            <el-table-column prop="" label="特种设备管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('特种设备管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="10">
            <el-table-column prop="" label="危险作业工作票作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '危险作业工作票作业'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="11">
            <el-table-column prop="" label="动火作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('动火作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="12">
            <el-table-column prop="" label="高空作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('高空作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="13">
            <el-table-column prop="" label="吊装作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('吊装作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="14">
            <el-table-column prop="" label="有限空间作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('有限空间作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="15">
            <el-table-column prop="" label="搬运安全作业"
              ><template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('搬运安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="16">
            <el-table-column prop="" label="电焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('电焊机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="17">
            <el-table-column prop="" label="气焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('气焊机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="18">
            <el-table-column prop="" label="砂轮机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('砂轮机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="19">
            <el-table-column prop="" label="手持电动工具安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '手持电动工具安全作业'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="20">
            <el-table-column prop="" label="起重机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('起重机安全作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="21">
            <el-table-column prop="" label="液氨泄漏探测仪检测作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '液氨泄漏探测仪检测作业'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="22">
            <el-table-column prop="" label="液氨使用作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('液氨使用作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="23">
            <el-table-column prop="" label="涉水作业">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('涉水作业')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="24">
            <el-table-column prop="" label="危险化学品">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('危险化学品')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="25">
            <el-table-column prop="" label="一般化学品">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('一般化学品')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="26">
            <el-table-column prop="" label="安全用电">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('安全用电')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="27">
            <el-table-column prop="" label="临时用电">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('临时用电')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="28">
            <el-table-column prop="" label="职业健康安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('职业健康安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="29">
            <el-table-column prop="" label="劳动防护">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('劳动防护')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="30">
            <el-table-column prop="" label="施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('施工安全')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="31">
            <el-table-column prop="" label="消防设施">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('消防设施')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="32">
            <el-table-column prop="" label="消防设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('消防设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="33">
            <el-table-column prop="" label="疏散指示">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('疏散指示')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="34">
            <el-table-column prop="" label="特种设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('特种设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="35">
            <el-table-column prop="" label="机械设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('机械设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="36">
            <el-table-column prop="" label="投加设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('投加设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="37">
            <el-table-column prop="" label="高低压电柜">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('高低压电柜')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="38">
            <el-table-column prop="" label="一般设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('一般设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="39">
            <el-table-column prop="" label="作业环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('作业环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="40">
            <el-table-column prop="" label="办公环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('办公环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="41">
            <el-table-column prop="" label="实验室环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('实验室环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="42">
            <el-table-column prop="" label="车间环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('车间环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="43">
            <el-table-column prop="" label="仓库环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('仓库环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="44">
            <el-table-column prop="" label="宿舍环境">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('宿舍环境')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="45">
            <el-table-column prop="" label="应急物资">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('应急物资')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="46">
            <el-table-column prop="" label="应急设备">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('应急设备')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="47">
            <el-table-column prop="" label="应急预案">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('应急预案')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="48">
            <el-table-column prop="" label="应急演练">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('应急演练')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="49">
            <el-table-column prop="" label="7S管理">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('7S管理')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="50">
            <el-table-column prop="" label="设备状态">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('设备状态')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="51">
            <el-table-column prop="" label="安全警示标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('安全警示标识')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="52">
            <el-table-column prop="" label="作业指导书标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('作业指导书标识')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="53">
            <el-table-column prop="" label="行为标识">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('行为标识')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="54">
            <el-table-column prop="" label="信息栏">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('信息栏')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="55">
            <el-table-column prop="" label="卫生用品存放">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('卫生用品存放')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="56">
            <el-table-column prop="" label="四色图">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('四色图')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="57">
            <el-table-column prop="" label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('疫情防控')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="58">
            <el-table-column prop="" label="管道安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '管道安装工程质量'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="59">
            <el-table-column prop="" label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('疫情防控')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="60">
            <el-table-column prop="" label="构筑物工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('构筑物工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="61">
            <el-table-column prop="" label="道路工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes('道路工程质量')
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="62">
            <el-table-column prop="" label="设备安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '设备安装工程质量'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="63">
            <el-table-column prop="" label="机电安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '机电安装工程质量'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="64">
            <el-table-column prop="" label="水环境治理工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.ArrAuditType.split(',').includes(
                      '水环境治理工程质量'
                    )
                      ? '√'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="reqData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from '../../api/http'

export default {
  data() {
    return {
      tableList: [],
      tabList: [
        {
          name: '区域检查',
          value: '区域审核',
        },
        { name: '文件检查', value: '文件审核' },
        {
          name: '考核细则',
          value: '安全考核',
        },
        {
          name: '内外审检查',
          value: '内外审审核',
        },
      ],
      elementHeight: 0,
      tabIndex: 0,
      dialogVisibleAsk: false,
      questionList: [],
      questionItem: {},
      isImport: true,
      deptList: [],
      postList: [],
    }
  },
  mounted() {
    this.getElementHeight()
    this.clickTab(0, '区域审核')
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52)
      })
    },
    clickTab(i, value) {
      this.tabIndex = i
      get('/api/FileQuestionlog/GetByType?AuditType=' + value)
        .then((resp) => {
          if (resp.code == 200) {
            this.tableList = resp.data
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
    iSImportQuestion(item, isImport) {
      this.questionItem = item
      this.isImport = isImport
      if (isImport) {
        this.dialogVisibleAsk = true
        var data = {
          CompName: item.compName,
          FunctionName: '',
          FileNo: '',
          FileName: '',
          Question: item.question,
          Answer: '',
          Version: '',
          RiskPoints: '',
          RiskMeasures: '',
          Module: '',
          IsCurrency: 0,
          IsFuncCurrency: 0,
          IsSpecial: 0,
          AreaAudit: 0,
          FileAudit: 0,
          arrArea: '',
          ArrAuditType: '',
        }
        this.questionList.push(data)
        this.getDeptList(item.compId)
      } else {
        this.reqIsImport(item)
      }
    },
    reqIsImport(item) {
      put(
        '/api/FileQuestionlog?Id=' +
          item.id +
          '&AuditType=' +
          this.tabList[this.tabIndex].value +
          '&IsAdd=' +
          (this.isImport ? 1 : 2)
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'warning',
              message: this.isImport ? '导入成功' : '不导入成功',
            })
            this.clickTab(this.tabIndex, this.tabList[this.tabIndex].value)
          } else {
            this.$message.error(resp.data.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isImport ? '导入失败' : '不导入失败')
        })
    },
    getDeptList(compId) {
      get('/api/Department/GetNoTree?CompId=' + compId).then((res) => {
        this.deptList = res.data
      })
    },
    changDept(e) {
      if (!e) return
      this.questionList[0].DeptName = e.label
      get(
        '/api/Post?CompId=' + this.questionItem.compId + '&DeptId=' + e.value
      ).then((res) => {
        this.postList = res.data
      })
    },
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    toChange(item, column) {
      if (column.index >= 11 && column.index <= 15) {
        var n = column.property
        item[n] === 1 ? (item[n] = 0) : (item[n] = 1)
      }
      if (column.index >= 16 && column.index <= 23) {
        if (item.arrArea.split(',').indexOf(column.label) >= 0) {
          var Nitem = item.arrArea.split(',')
          var index = item.arrArea.split(',').indexOf(column.label)
          Nitem.splice(index, 1)
          item.arrArea = Nitem.toString()
        } else {
          item.arrArea === ''
            ? (item.arrArea = column.label)
            : (item.arrArea = item.arrArea + ',' + column.label)
        }
      }
      if (column.index > 23) {
        if (item.ArrAuditType.split(',').indexOf(column.label) >= 0) {
          var Nitem2 = item.ArrAuditType.split(',')
          var index2 = item.ArrAuditType.split(',').indexOf(column.label)
          Nitem2.splice(index2, 1)
          item.ArrAuditType = Nitem2.toString()
        } else {
          item.ArrAuditType === ''
            ? (item.ArrAuditType = column.label)
            : (item.ArrAuditType = item.ArrAuditType + ',' + column.label)
        }
      }
    },
    reqData() {
      post('/api/QuestionPool/BatchAdd', {
        data: JSON.stringify(this.questionList),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.reqIsImport(this.questionItem)
            this.dialogVisibleAsk = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('导入失败')
        })
    },
    handleClose() {
      this.questionList = []
      this.dialogVisibleAsk = false
    },
  },
}
</script>

<style lang="less" scoped>
.tab {
  padding: 10px 0;
  .el-button {
    margin-right: 10px;
  }
}
/deep/ .el-input__inner {
  background-color: white !important;
}
</style>